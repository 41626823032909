import { Link as BaseLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {
    FaRegCopyright as IconCopyright,
    FaGithub as IconGithub,
    FaLinkedin as IconLinkedIn,
    FaMapMarker as IconMapMarker,
    FaTwitter as IconTwitter,
} from 'react-icons/fa';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import styled from 'styled-components';

import withLocale from '../components/i18n/withLocale';
import Logo from './Logo';
import BCorpLogo from '../../static/images/b-corp-logo.png';
import MaxWidthContainer from './MaxWidthContainer';

const Container = styled.div`
    background-color: #333333;
    color: white;
    flex-grow: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    padding: 2rem 0;
`;

const Link = styled(BaseLink)`
    color: ${({ theme }) => theme.white} !important;
`;

const InnerContainer = styled(MaxWidthContainer)`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 600px) {
        flex-direction: column;
    }
    @media print {
        padding: 0;
        font-size: 8px;
        line-height: 8px;
        height: 8px;
    }
`;

const Zone = styled.div`
    margin: 0 1rem;
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 600px) {
        margin: 1rem 0 0 0;
    }
`;

const StyledLogo = styled(Logo)`
    height: 30px;
`;

const StyledIconMapMarker = styled(IconMapMarker)`
    color: ${({ theme }) => theme.white};
    margin-right: 0.5rem;
`;

const StyledIconCopyright = styled(IconCopyright)`
    color: ${({ theme }) => theme.white};
    margin-right: 0.5rem;
`;

const StyledIconTwitter = styled(IconTwitter)`
    color: ${({ theme }) => theme.white};
`;

const StyledIconGithub = styled(IconGithub)`
    color: ${({ theme }) => theme.white};
`;

const StyledIconLinkedIn = styled(IconLinkedIn)`
    color: ${({ theme }) => theme.white};
`;

const SocialLink = styled.a`
    color: ${({ theme }) => theme.white};
    text-decoration: none;
    margin: 0 0.5rem 0 0;
`;

// TODO: Change the copyright date in 2025 ;)
const Footer = ({ t, locale }) => (
    <Container role="contentinfo">
        <InnerContainer>
            <StyledLogo inverted />
            <Zone>
                <StyledIconCopyright /> Marmelab 2013-2024.{' '}
                {t('footer.copyrights')}
            </Zone>
            <Zone>
                <StyledIconMapMarker />
                <span>4 rue Girardet, 54 000 Nancy, FRANCE</span>
            </Zone>
            <Zone>
                <Link to={`/${locale}/legal`}>{t('legal.title')}</Link>
            </Zone>
            <Zone>
                <SocialLink
                    aria-label={t('footer.linkedin')}
                    href="https://linkedin.com/company/marmelab"
                >
                    <StyledIconLinkedIn />
                </SocialLink>
                <SocialLink
                    aria-label={t('footer.github')}
                    href="https://github.com/marmelab"
                >
                    <StyledIconGithub />
                </SocialLink>
                <SocialLink
                    aria-label={t('footer.twitter')}
                    href="https://twitter.com/marmelab"
                >
                    <StyledIconTwitter />
                </SocialLink>
                <a
                    href="https://www.bcorporation.net/en-us/find-a-b-corp/company/marmelab/"
                    style={{ marginLeft: '1em' }}
                    title="Marmelab is a Certified B Corporation"
                >
                    <img
                        src={BCorpLogo}
                        alt="Certified B Corporation"
                        style={{ height: 40 }}
                    />
                </a>
            </Zone>
        </InnerContainer>
    </Container>
);

Footer.propTypes = {
    t: PropTypes.func.isRequired,
    locale: PropTypes.string,
};

export default compose(withLocale, translate())(Footer);
